<template>
  <VMenu
    content-class="elevation-1"
    :disabled="disabled"
    offset-y
    style="border: 1px solid black"
  >
    <template v-slot:activator="{ on, attrs }">
      <VBtn
        v-bind="attrs"
        :block="!disabled"
        class="elevation-0 d-flex justify-space-between text-none"
        :class="disabled ? 'px-4' : 'pl-1 pr-2'"
        :color="stateParams[stateToEdit].color"
        :height="height"
        :style="{'color': stateParams[stateToEdit].textColor}"
        v-on="on"
      >
        <div :class="small ? 'subtitle-2' : 'subtitle-1'">
          <VIcon
            v-show="!disabled"
            v-text="'mdi-circle-small'"
          />
          {{ stateParams[stateToEdit].title }}
        </div>
        <VIcon
          v-if="!disabled"
          size="18"
          v-text="'mdi-chevron-down'"
        />
      </VBtn>
    </template>
    <VListItemGroup
      v-model="stateToEdit"
      class="white"
    >
      <VListItem
        v-for="(state, index) in states"
        v-show="state !== stateToEdit"
        :key="index"
        dense
        :value="state"
      >
        <VListItemTitle
          class="subtitle-1 pl-4"
          v-text="stateParams[state].title"
        />
      </VListItem>
    </VListItemGroup>
  </VMenu>
</template>

<script>
import { allStateParams } from '@/conditions/states';

export default {
  props: {
    value: { // state
      type: String,
      default: 'active',
    },
    stateParams: {
      type: Object,
      default: () => allStateParams,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '38',
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  data() {
    return {
      stateToEdit: 'active',
      states: ['active', 'disabled'],
    };
  },
  mounted() {
    if (this.value) {
      this.stateToEdit = this.value;
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.stateToEdit = val;
      }
    },
    stateToEdit(val) {
      this.$emit('input', val);
    },
  },
};
</script>
